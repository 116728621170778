*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Updated Design

body {
  background-color: $light !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.5; }

h1, .h1 {
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}

h2, .h2 {
  /*font-size: 2rem;
  letter-spacing: 0.05rem;*/
  text-align: left;
  font: Bold 20px/24px Lato !important;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-weight: 700 !important;
}

h3, .h3 {
  font-size: 1.75rem;
  font-weight: $font-weight-light !important;
  letter-spacing: 0.05rem;
}

h4, .h4 {
  font-size: 1.5rem;
  font-weight: $font-weight-light !important;
  letter-spacing: 0.05rem;
}

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

h1, h2, h3, h4, h5, h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1199px) {
  h1, .h1 {
    font-size: 28px;
    line-height: 100%;
  }

  h2, .h2 {
    font-size: 24px !important;
    line-height: 100% !important;
  }

  h3, .h3 {
    font-size: 22px;
    line-height: 100%;
  }

  h4, .h4 {
    font-size: 20px;
    line-height: 100%;
  }
  h5, .h5 {
    font-size: 18px;
    line-height: 100%;
  }
}

.poster-text{
  font-size: 2rem !important;
  letter-spacing: .05rem;
  line-height: 1.5 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

a {
  color: $primary;
  text-decoration: none;
  background-color: transparent;
  &:hover{
    text-decoration: none !important;
  }
  &.action{
    font-size: 1.4em;
  }
}

img{ object-fit: cover;}

.text{
  &-success{
    color: $success !important;
  }
  &-dark{
    color: $dark !important;
  }
  &-secondary{
    color: $secondary !important;
  }
  &-light{
    color: $light !important;
  }
  &-primary {
    color: $primary !important;
  }
}

a.text-light:hover{
  color: $light !important;
}

.badge-secondary {
  color: $dark;
  background-color: $secondary;
}

.hub-text {
  margin: 2rem 0;
}

.mce-container {
  p, label {
    font: 14px/23px Lato !important;
  }
}

#wrapper main {
  min-height: calc(100vh - 141px);
}

.to-top {
  a.back-to-top{
    background-color: black;
    padding: 1.2rem 2rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 2rem;
    visibility: hidden;
    position: absolute;
    height: 0;
    padding: 0;
    span {
      font-size: 0;
    }
  }
  padding: 0;
}

#wb-rsz {
  font-size: 0;
  position: absolute;
}

.video-container .play-btn {
  position: absolute;
  height:80px;
  top: 40%;
  margin-top: -40px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
}

.no-box-shadow {
  box-shadow: none !important;
}

.no-scroll {
  max-height: 100vh !important;
  overflow: hidden !important;
  margin: 0 !important;
  &::after {
    content: '';
    position: absolute;
    display: block;
    background-color: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1029;
  }
}

.text-black {
  color: black;
}

#pv-content {
  .container-fluid {
    &:first-of-type {
      margin-top: 5rem;
    }
  }
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.view-login{
  #pv-content{
    margin-top: 5rem;
  }
}

.btn {
  font-family: $headings-font-family;
  &-primary{
    border-color: $primary;
    background-color: $primary;
    color: #fff;
    &:hover{
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
  &-outline-primary{
    color: $primary;
    border-color: $primary;
    &:hover{
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
  &-outline-light:hover{
    border-color: $skyblue;
    color:$skyblue !important;
  }

  &-lg{
    font-size: $h3-font-size !important;
    font-weight: $font-weight-light !important;
    &.action{
      height: 2em;
      line-height: 1em;
    }
  }
  &-danger{
    background-color: $danger;
    border-color: $danger;
    &:hover{
      background-color : #f04369;
      border-color: #f04369;
      color: #fff !important;
    }
  }
  &-success{
    background-color: $success !important;
    border-color: $success !important;
  }
  &-block{
    width: 100%;
  }
  &-secondary{
    background-color: $secondary;
    border-color: $secondary;
    &:hover{
      background-color: #e76044;
      border-color : #e76044;
    }
  }
  &-link,&-link:focus{
    color: $primary;
  }
}

.navbar {
  .nav-link{
    font-size: $navbar-navlink-font-size;
    font-weight: $navbar-navlink-font-weight;
  }
}

.icon-16 {
  height: 1rem;
  width: 1rem;
}

.icon-18{
  height: 1.2rem;
}

.h-25px{
  height: 1.6rem;
}

.three-dots {
  font-size: $three-dots;
  color: $white;
  vertical-align: middle;
}

.h-32px {
  height: 32px;
}

.h-48px {
  height: 48px;
}

.h-64px {
  height: 64px;
}

.h-128px {
  height: 128px;
}

.h-256px {
  height: 256px;
}

.h-500px {
  height: 500px;
}

.h-700px {
  height: 700px;
}

@media (min-width: 767px) {
  .h-md-100{
    height: 100%;
  }
}



.w-128px {
  width: 128px;
}

.card-header {
  p {
    margin-bottom: 0;
  }
}

.pt-8 {
  padding-top: 8rem !important;
}

.mb-down{
  margin-bottom: 2rem !important;
}

.max-vh-100 {
  max-height: 100vh;
}


.pv-icon{
  font-size: $pv-icon;
  vertical-align: middle;
}

.bulb{
  vertical-align: middle;
  height: 17px;
  width: 14px;
}

// On the Ideas page

.pv-container-header{
	
	h3{
		line-height: 1.5em !important;
	}
}

.pv-pagination{
  .paging{
    a, span{
      padding: 0.8rem;
    }
    @include media-breakpoint-down(sm) {
      a, span {
        padding: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}

.pv-collab .no-rows {
  margin-bottom: 1rem;
}

@media (max-width: 767px) { // The point in which the menu passes from normal to "hamburger" menu in PH v2

  #pv-content {
    .container-fluid {
      &:first-of-type {
        margin-top: 0 !important;
      }
    }
  }

}

.hidden-mod{ // For modules which needs to be hidden by class

  display: none;

}

.normal-list{
  @extend .h4;
  margin-left: 1em;
}

.normal-list li{
  margin-bottom: 0.8em;
}


.bg-temp-img-1 {
  background: linear-gradient(rgba(0,0,0,.7),rgba(0,0,0,.7)), url("/images/patient-hub/placeholder1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bg-temp-img-2 {
  background: $img-bg-overlay-color, url("/images/patient-hub/placeholder7.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

@include media-breakpoint-up(lg) {
  .vh-lg-100 {
    height: 100vh !important;
  }
}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(xs) {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .no-px-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*.card .card-header .text-right img {
  !* TEMPORARY *!
  display: none;
}*/

.navbar-brand {
  padding-top: .5rem;
  padding-bottom: .125rem;
}

.card-header .pr-6px {
  padding-right: 6px !important;
}

#actual-navbar .navbar-logo-img, .footer-logo-img {
    max-height: 60px;
    width: Min(60vw, 270px);
}

html.view-feed.layout-react #actual-navbar {
  position: relative !important;
  top: -16px !important;
}

.less-gutters {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}
.less-gutters > .row {
  margin: 0 -7.5px;
}
.less-gutters > .row > [class^="col-"] {
  padding: 0 7.5px;
}

.record-view {
    .section-price.section-main > .container{
      max-width: 100% !important;
    }

    .to-top *,
    #pv-footer,
    #pv-header {
      display: none;
    }

    #pv-content > .container{
      padding-top: 0 !important;
      max-width: 100%;
    }
}

