$container-padding: 8rem;

$bubbletransition: 2s;

$lilypad-size: 20vw;
$lilypad-max-size: 200px;

$jumbolilypad-size: 40vw;
$jumbolilypad-max-size: 400px;

.home, .learnings {
    h1, .h1 {
        font-size: 48px;
        line-height: 65px;
        font-weight: bold;
        font-family: "Open Sans", sans-serif;
    }
    h2, .h2 {
        font-size: 36px !important;
        line-height: 49px !important;
        font-family: "Open Sans", sans-serif;
    }
    h3, .h3 {
        font-size: 30px;
        line-height: 41px;
        font-family: "Open Sans", sans-serif;
    }
    h4, .h4 {
        font-size: 24px;
        line-height: 33px;
        font-family: "Open Sans", sans-serif;
    }
    h5, .h5 {
        font-size: 20px;
        line-height: 27px;
        font-family: "Open Sans", sans-serif;
    }
    p, .p {
        font-size: 16px;
        line-height: 22px;
        font-family: "Open Sans", sans-serif;
    }
    span, .span {
        font-family: "Open Sans", sans-serif;
    }
    @media screen and (max-width: 1199px) {
        h1, .h1 {
            font-size: 28px;
            line-height: 100%;
        }

        h2, .h2 {
            font-size: 24px !important;
            line-height: 100% !important;
        }

        h3, .h3 {
            font-size: 22px;
            line-height: 100%;
        }

        h4, .h4 {
            font-size: 20px;
            line-height: 100%;
        }
        h5, .h5 {
            font-size: 18px;
            line-height: 100%;
        }
    }
    .hero {
        text-align: center;
        padding: 126px 0 204px 0;
        background: linear-gradient(0deg, rgba(91, 164, 172, 0.75), rgba(91, 164, 172, 0.75)), url("/images/iog/home-hero.png");
        margin-bottom: 60px;
        @media screen and (max-width: 640px) {
            padding: 50px 0 100px 0;
        }
        h2 {
            text-align: center;
        }

        .logos {
            margin-bottom: 44px;
            img.size-1 {
                height: auto;
                width: Min(490px, 90vw);
                display: inline-block;
                margin-right: 25px;
                margin-bottom: 25px;
                @media screen and (max-width: 640px) {
                    margin-right: 0;
                }
            }
            img.size-2 {
                height: 77px;
                width: auto;
                display: inline-block;
            }
        }
        .options {
            button {
                margin-right: 16px;
                margin-top: 16px;
                &:last-of-type {
                    marign-right: 0;
                }
            }
        }
        .learnings-special {
            margin-top: 30px;
            a {
                color: black;
                text-decoration: underline;
                &:hover {
                    color: #d16735;
                    text-decoration: underline !important;
                }
            }
        }
    }
    .blog-section {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 41px;
        overflow: hidden;
        position: relative;
        @media screen and (max-width: 767px) {
            display: block;
        }
        .picture {
            flex: 0 0 300px;
            width: 300px;
            position: relative;
            @media screen and (max-width: 767px) {
                width: 100%;
                height: 400px;
            }
            .aspect-hack {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: url("/images/iog/Ryan.jpg");
                background-size: cover;
                background-position: center;
                @media screen and (max-width: 990px) and (min-width: 768px) {
                    bottom: 300px;
                }
            }
            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.75);
                color: white;
                padding: 19px 14px 17px 14px;
                @media screen and (max-width: 990px) and (min-width: 768px) {
                    bottom: 300px;
                }
            }
        }
        .text {
            padding: 16px;
            background-color: white;
        }
    }
    .webinar-series {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 40px;
        [class^="banner-"], [class*=' banner-'] {
            height: 150px;
            width: 100%;
            @media (min-width: 576px) {
                height: 200px;
            }
            @media (min-width: 768px) {
                height: 250px;
            }
            @media (min-width: 992px) {
                height: 300px;
            }
            @media (min-width: 1200px) {
                height: 350px;
            }
            @media (min-width: 1400px) {
                height: 400px;
            }
        }
        .banner-1 {
            background: url("/images/iog/Digital-Gov-Series-Banner-1.png");
            background-size: cover;
            background-position: center;
        }
        .banner-2 {
            background: url("/images/iog/construction-banner.png");
            background-size: cover;
            background-position: center;
        }
        .banner-3 {
            background: url("/images/iog/maple-leaf-banner.png");
            background-size: cover;
            background-position: center;
        }
        .banner-4 {
            background: url("/images/iog/work-circle-banner.jpg");
            background-size: cover;
            background-position: center;
        }
        .content {
            padding: 16px;
            .panelists {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .panel-card {
                    flex: 1 0 0;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    margin-right: 16px;
                    overflow: hidden;
                    margin-bottom: 12px;
                    @media screen and (max-width: 990px) and (min-width: 768px) {
                        flex: 1 0 40%;
                        &:nth-of-type(2) {
                            margin-right: 0;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        flex: 1 0 100%;
                        margin-right: 0;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    img.head-shot {
                        width: 100%;
                        height: auto;

                    }
                    .content {
                        padding: 16px;
                    }
                }
            }
            .options > button {
                margin-left: 8px;
                margin-top: 12px;
                @media screen and (max-width: 640px) {
                    margin-left: 0;
                }
            }
        }
    }
    button,a.button {
        border: 1px solid #D16735;
        border-radius: 5px;
        padding: 12px 24px;
        font-size: 20px;
        line-height: 24px;
        cursor: pointer;
        &.solid {
            background: #D16735;
            color: white;
            &:hover {
                background: white;
                color: #D16735;
            }
        }
        &.outline {
            background: white;
            border: 1px solid #D16735;
            color: #D16735;
            &:hover {
                background: #D16735;
                color: white;
            }
        }
        @media screen and (max-width: 640px) {
            display: block;
            width: 100%;
        }
    }
    .flex-row-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    span.date {
        margin-bottom: 12px;
        display: inline-block;
    }
}
