.card-img-top {
  object-fit: cover;
  border-radius: 0 !important;
}

.card-header {
  padding: 0.9375rem;
  .h1, .h2, .h3, .h4, .h5, .h6 ,
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  h5,.h5{
    font : normal bold 	0.875rem/17px Lato;
  }

  p {
    margin-bottom: 0;
  }
  .col {
    .h3.action,
    h3.action{
      font-weight: 700 !important;
    }
  }
  &.viewing-card{
    background: transparent linear-gradient(90deg, #00A566 0%, #5C97BF 100%) no-repeat padding-box;
  }

  &.action-card{
    background: transparent linear-gradient(90deg, #D46A43 0%, #C0392B 100%) no-repeat padding-box;
  }
  >.row{
    margin-top: 0 !important; margin-bottom: 0 !important;
  }
}

.viewing-card{
  background: transparent linear-gradient(96deg, #00A566 0%, #5C97BF 100%) no-repeat padding-box;
}

.action-card{
  background: transparent linear-gradient(96deg, #D46A43 0%, #C0392B 100%) no-repeat padding-box;
}

.card {
  overflow: hidden;
  border-radius: 5px !important;
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  transition: .3s;

  @include media-breakpoint-up(md){
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(sm) {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .card {
    box-shadow: 0 0 0 rgba(203, 198, 195, 0);
  }

  .order-before {
    order: -1;
  }

  .btn-tag{
    border-color: $dark;
    background-color: #fff;
    font-weight : 700;
    &:hover,&.active{
      @extend .btn-outline-dark;
    }
  }

  .card-body{
    .card-title{
      text-align: left;
      font: Bold 20px/24px Lato !important;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
      vertical-align: top;
      display: inline-block;

    }
    p{
      text-align: left;
      font : normal .875rem/17px Montserrat;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
    }
    .follow-btn{
      font-size: 12px;
      line-height: 14px;
      border-color: $black;
      color: $black;
      transition: all .3s ease-in;
      &:hover{
        background-color: $black;
        color: $white;
      }
    }

    .stat-box{
      text-align: left;
      font : normal normal 0.75rem/14px Lato;
      letter-spacing: 0;
      opacity: 1;
      margin: .5rem 0 .5rem!important;
      >div.col-auto{
        margin-bottom: 1rem !important;
      }
      .dot {
        background: #4d4d4d 0 0 no-repeat padding-box;
        width: 4px;
        height: 4px;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
    img{
      max-height: 256px;
      height: auto;
    }
  }

  .card-footer{
    padding: 1.1rem 1.25rem 1.2rem;
    .clap {
      flex-grow:1;
      text-align:center;
      a {
        display:inline-block;
        width:100%;
      }
      padding-top: .5rem;
      padding-bottom: .6rem;
      &:hover {
        border-radius: 5px;
        background-color: #CCC;
      }
    }
    .comment {
      flex-grow:1;
      text-align:center;
      cursor:pointer;
      padding-top: .5rem;
      padding-bottom: .6rem;
      &:hover {
        border-radius: 5px;
        //background-color: #CCC;
      }
    }
    .share, .rate {
      flex-grow:1;
      text-align:center;
      padding-top: .5rem;
      padding-bottom: .6rem;
      &:hover {
        border-radius: 5px;
        background-color: #CCC;
      }
    }
    span.p:not(.icon-span):not(.pv-icon){
      text-align: center;
      font : normal normal 0.75rem/14px Lato;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
    }
  }
  &:hover {
    box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);

    .card {
      box-shadow: 0 0 0 rgba(203, 198, 195, 0);
    }
  }
}

.card, .card-header, .card-body, .card-footer {
  background-color: #FFF;
  border-width: 0 !important;
  .embed-survey{
    width: 100%;
    padding: 0;
    iframe{
      width: 100%;
    }
  }
  .pv-icon{
    font-size: 1.3rem;
    vertical-align: middle;
  }
  .icon-18 {
    font-size: 1.22rem !important;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    color: #000;
  }
}

.com_publivateideamodule.view-detail{ // The Ideas page
  .card-img-overlay{
    width: 11em !important;
    height: 5.5em !important;
  }
}

.card-columns {

  @include media-breakpoint-down(md){
    column-count: 1;
  }

  @include media-breakpoint-up(md){
    column-count: 2;
  }

  @include media-breakpoint-up(lg){
     column-count: 3;
   }
}

.masony {
  float: left;

  @include media-breakpoint-down(md){
    width: 100%;
  }

  @include media-breakpoint-up(md){
    width: calc(50% - 16px);
  }

  @include media-breakpoint-up(lg){
    width: calc(32% - 16px);
  }
}

.card-header .pr-6px {
  padding-right: 6px !important;
}