/*
    Created on : 19-Apr-2018, 2:41:42 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

.video-container {
  width: 100%;
  .play-btn {
    position : absolute;top : 50%;left : 50%;
    color : white;
    padding : 10px;
    border : none;
    cursor : pointer;
    border-radius : 50%;
    width : 5rem;
    height : 5rem;
    &:hover {
      opacity: 0.8;
    }
  }

  img {
    width: 100%;
  }
}