footer .container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
#pv-footer > .moduletable {
	padding: 0 !important;
	margin: 0 !important;
}

#custom-footer-module {
	background-color: #73a09c;
	a {
		color: black;
	}
}

// Large devices (desktops, 992px and up)
@media (max-width: 991px) {
	footer .container{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	footer{
		width: 100%;
		height: auto;
		//text-align: center;
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		.copyright{
			margin-top: 1rem;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
	footer {
		.nav {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			.nav-item {
				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
}

