#system-message-container {
  margin: 60px 1.5rem 1.5rem;

  @include media-breakpoint-down(md) {
    /*margin: 30px 1.5rem 1.5rem;*/
  }

  @include media-breakpoint-down(sm) {
    /*margin: 1rem;*/
  }
}

#system-message {
  text-align: center;

  .alert {
    text-align: left;
    margin: 1.5rem 30%;

    &-heading {
      color: #0b172a;
    }

    &-message {
      color: #201ec4;
      font-size: 16px;
      background: #f0f9ff;
    }

    &-error,
    &-warning{
      color: red;
    }

    @include media-breakpoint-down(md) {
      margin: 1rem 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 1rem;
    }

  }
}