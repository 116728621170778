@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i');

@import "idea/variables";
//@import "idea/global";
@import "idea/main-page";
@import "idea/modal";
@import "idea/single-page";
@import 'idea/rating';
.pv-collab {
  @import "idea/media";
}

