#pv-header{
	background-color: transparent;
	position: relative;
}

#registrationTable {
	margin-top: 58px;
}

/* ========== HEADER TOP ========== */
.header-top{
	background-image: url('../img/header-top.png');
	height: 200px;
	background-position: center;
	background-size: cover;
}


@include media-breakpoint-down(md) {
	nav.navbar /*:not([class*=' navbar-expand-'])*/ {
		background-color: white !important;
		padding-right: 0; // 16px
		padding-left: 0;
		box-shadow: 0 0 10px rgba(0,0,0,.12);
		a.navbar-brand {
			margin-left: 16px;
			padding: 0;
		}
		button.navbar-toggler {
			margin-right: 24px;
			padding: 0;
			border: none;
			color: rgb(50, 146, 216);
			margin-top: 2px;
			&.collapsed {
				color: rgba(0, 0, 0, 0.5);
			}
		}
		#navTop {
			margin-top: 8px;
			ul.navbar-nav:first-child::before {
				margin-top: 0;
			}
			ul.navbar-nav {
				&::before {
					content: "";
					margin: 8px 16px;
					border-top: 1px solid #C6C6C6;
				}
				li.nav-item {
					text-align: left;
					& > a, & > span {
						display: inline-block;
						padding: 12px 0 12px 16px;
						color: black;
						cursor: pointer;
						font-weight: 400;
						width: 100%;
						&:hover {
							background-color: #f8f9fa;
						}
					}

				}
				li.dropdown.parent {
					span.nav-header::after {
						content: "";
						display: inline-block;
						width: 0;
						height: 0;
						border-left: 3px solid transparent;
						border-right: 3px solid transparent;
						border-top: 6px solid black;
						position: relative;
						right: -6px;
						bottom: 2px;
						transform: rotate(0);
						transition: transform .4s ease;
					}
					& > .dropdown-menu {
						border-left: 1px solid #007FAA;
						border-right: none;
						border-top: none;
						border-bottom: none;
						left: 0;
						right: auto;
						padding: 0;
						margin-left: 16px;
						box-shadow: none;
						border-radius: 0;

						& > ul {
							list-style: none;
							& > li {
								text-align: left;
								& > .dropdown-item {
									padding: 12px 0 12px 16px;
									border: none;
									color: black;
									font-weight: 400;
								}
							}
						}
					}
				}
				li.dropdown.parent {
					&:hover, &:focus, &:focus-within, &.clicked {
						span.nav-header {
							color: rgb(50, 146, 216);
							&::after {
								border-top-color: rgb(50, 146, 216);
								transform: rotate(90deg);
							}
						}
						& > .dropdown-menu {
							display: block;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	nav.navbar /*[class*=' navbar-expand-']*/
	{
		padding-top: 0;
		padding-bottom: 0;
		flex-wrap: wrap;
		background-color: white !important;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
		a.navbar-brand {
			padding: 0;
		}
		#navTop {
			& > ul {
				margin-left: auto;
			}

			& > ul.left {
				margin-left: 0;
			}

			.navbar-nav .nav-item.current a, ul li.nav-item.current .nav-header {
				color: $primary;
			}

			.navbar-nav .nav-item a, ul li.nav-item .nav-header {
				color: black;
				padding: 23px 1rem;
				width: 100%;
				display: inline-block;

				&:hover {
					cursor: pointer;
				}
			}

			.dropdown.parent {
				position: relative;

				span.nav-header {
					font-size: 1rem;
					font-weight: 300;
					color: rgba(0, 0, 0, .5);
					display: inline-block;
					vertical-align: middle;
					color: black;
					transition: color .4s ease;
				}

				span.nav-header::after {
					content: "";
					display: inline-block;
					width: 0;
					height: 0;
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-top: 6px solid black;
					position: relative;
					right: -6px;
					bottom: 2px;
					transform: rotate(0);
					transition: transform .4s ease, border-top .4s ease;
				}

				.dropdown-menu {
					opacity: 0;
					height: 0;
					overflow: hidden;
					position: absolute;
					border-left: none;
					border-top: none;
					border-bottom: none;
					border-right: 1px solid #007FAA;
					right: 0;
					left: auto;
					width: max-content;
					display: block;
					border-radius: 0;
					background-color: inherit;
					box-shadow: none;
					min-width: 0;
					padding: 0;
					margin-top: 0;

					& > ul {
						list-style: none;

						& > li {
							text-align: right;
							margin-top: 8px;
							transform: translateX(100%);
							transition: transform .4s ease;

							& > .dropdown-item {
								color: black;
								display: inline-block;
								padding: 5px 10px;
								border: 1px solid black;
								border-right: none;
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
								background-color: white;
								width: auto;

								&:hover {
									color: #007FAA;
									cursor: pointer;
								}
							}
						}
					}
				}

				&:hover, &:focus, &:focus-within, &.clicked {
					span.nav-header {
						color: #007FAA;
						&::after {
							transform: rotate(90deg);
							border-top: 6px solid #007FAA;
						}
					}
					.dropdown-menu {
						height: auto;
						opacity: 1;
						z-index: 1;

						& > ul > li {
							transform: translateX(1px);
						}
					}
				}
			}

			.left .dropdown.parent {
				.dropdown-menu {
					border-left: 1px solid #007FAA;
					border-right: none;
					left: 0;
					right: auto;

					& > ul > li {
						text-align: left;
						transform: translateX(-100%);

						& > .dropdown-item {
							border-left: none;
							border-right: 1px solid black;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
						}
					}
				}

				&:hover, &.clicked, &:focus, &:focus-within {
					.dropdown-menu > ul > li {
						transform: translateX(-1px);
					}
				}
			}
		}
	}
}

/* ========== HEADER PAGES ========== */
.pages{
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	.content-left{
		h1{
			margin-bottom: 20px;
		}
		img{
			margin-bottom: 20px;
		}
		.h-paragraph{
			display: block;
			max-width: 500px;
			#text{
				display: none;
			}
		}
		.m-paragraph{
			display: none;
			position: relative;
			#m-text {
				display: none;
			}
			a{
				display: inline-block;
				color: $black;
				font-size: 20px;
				margin-left: 5px;
				@include transition;

				i{
					&:hover{
						text-decoration: none;
						color: $blue;
					}
				}
			}
		}
		.content-btn{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 30px;
			.dr-jack{
				font-style: italic;
			}
		}
	}
	.content-right{
		border-left: 1px solid rgba(0,0,0, .2);

		h1{
			margin-bottom: 20px;
		}
		p{
			margin-bottom: 10px;
		}
		h5{
			font-size: 20px !important;
			font-weight: 600;
			margin-bottom: 30px;
			line-height: 20px;
		}

		.survey{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.survey-heading{
				display: block;
				width: 240px;
				font-size: 24px!important;
			}

			.task-survey{
				ul{
					list-style: none;
					li{
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						width: 220px;
						height: 40px;
						line-height: 40px;
						border-radius: 100px;
						-webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
						-moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.2);
						box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
						&:not(:last-child){
							margin-bottom: 10px;
						}

						input[type=radio]{
							position: absolute;
							visibility: hidden;
						}
						label{
							display: block;
							position: relative;
							margin-left: 20px;
							height: 40px;
							width: 100%;
							line-height: 50px;
							cursor: pointer;
							z-index: 9;
							-webkit-transition: all 0.25s linear;
							-moz-transition: all 0.25s linear;
							transition: all 0.25s linear;
						}
						&:hover label{
							color: $blue;
						}

						.check{
							display: block;
							position: absolute;
							border: 2px solid #7f8c8d;
							border-radius: 100%;
							height: 20px;
							width: 20px;
							right: 20px;
							-webkit-transition: border 0.25s linear;
							-moz-transition: border 0.25s linear;
							transition: border 0.25s linear;
							&::before{
								display: block;
								position: absolute;
								content: '';
								border-radius: 100%;
								height: 10px;
								width: 10px;
								margin: auto;
								top: 2px;
								left: 2px;
								-webkit-transition: background 0.25s linear;
								-moz-transition: background 0.25s linear;
								transition: background 0.25s linear;
							}
						}
						&:hover .check{
							border: 3px solid $blue;
						}
						input[type=radio]:checked ~ .check {
							border: 3px solid $blue;
						}
						input[type=radio]:checked ~ label {
							color: $blue;
						}
						input[type=radio]:checked ~ .check::before{
							background: $blue;
						}
					}
				}

				.survey-btn{
					outline: none;
					cursor: pointer;
					float: right;
					border: none;
					color: $blue;
					transition: all .3s;
					font-size: 18px;
					margin-top: 10px;
					i{
						font-size: 20px;
						@include transition;
						&:hover{
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px){
	.pages{

		.content-left{
			img{
				width: 100%;
			}
			.h-paragraph{
				max-width: 100%;
			}
		}

		.content-right{
		}
	}
}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px){
	.header-top{
		background-image: url('../img/t-head-small.png');
		height: 90px;
		background-position: center;
		background-size: cover;
	}

	.pages{
		.content-left,
		.content-right{
			margin-bottom: 15px;
			margin-top: 15px;
		}

		.content-left{
			h1{
				margin-bottom: 10px;
			}
			img{
				display: none;
			}
			.h-paragraph{
				display: none;
			}
			.m-paragraph{
				display: block;
			}
			.primary-btn{
				display: none;
			}

			.content-btn{
				justify-content: flex-end;
			}
		}

		.content-right{
			.survey-heading{
				width: 100%;
				margin-bottom: 10px;
			}
			.survey{
				align-items: flex-start;
				flex-direction: column;
				.task-survey{
					ul{
						li{
							display: inline-block;
							width: 100%;
							height: 40px;
							label{
								line-height: 40px
							}
							.check{
								line-height: 40px;
								top: 10px;
							}
						}
					}
					.survey-btn{
						display: block;
						float: none;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
@media only screen and (max-width : 575px){

	.pages{
		.content-left{
			.content-btn{
				margin-top: 10px;
			}
		}
		.content-right{
			padding: 20px 15px;
			h5{
				font-size: 16px !important;
				font-weight: 600 !important;
				margin-bottom: 20px;
				line-height: 16px;

			}
			.survey-heading{
				font-size: 20px !important;
				font-weight: bold;
				margin-bottom: 20px;
			}
			label{
				font-size: 14px!important;
			}
		}
	}

}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px){

}

.navbar-nav{
	> .nav-item{
		a {
			@extend .nav-link,.hover-float,.hover-primary;
		}
		&.active > a, &.active > span {
			color: $primary;
		}
		.dropdown-menu .active a {
			color: $primary;
		}
	}
}


