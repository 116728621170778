/* Styling for all modules in the template can be added here*/

// Moudle top position
.main-top .moduletable {
	width: 100%;

	.mod-challenge {
		padding: 3rem 0 0;

		.time-remaining {
			margin: 0 auto;
		}
		.countdown_row{
			margin: 0 auto;
		}
	}


}

.moduletable-rnd,
.module,
.moduletable {
	@extend .px-2;
	@extend .py-4;
	@extend .mb-3;

	&.moduletable-rnd,
	&.rounded-border {
		border: 2px solid $pv-light-blue;
		border-radius: 10px;

	}
}

div.moduletable{
	padding-bottom: 15px;
}

div.moduletable.rounded{
	border: 1px solid $pv-theme;
}

.moduletable-rnd,
div.rounded-border{
	border: 2px solid $pv-light-blue;
	border-radius: 10px;
}

div.icon{
	padding-right: 0;
	padding-left : 5px;
}

// CStream Module

div.mod_ccstream{

	ul#ticker{
		height: 400px;
		overflow: hidden;
		margin: 0;
		padding: 0;
		-webkit-box-shadow: 0 1px 3px rgba(0,0,0, .4);
		list-style: none;
	}

	ul#ticker li {
		padding: 20px;
		display: block;
		background: #EDF6FD;
		color: #333;
		border-bottom: 1px solid #ddd;
		border-radius: 10px;
		text-align: center;
		font-size: 13px;
	}
	.add-idea{
		color: var(--white);
		text-decoration: none;
	}

	.add-idea:hover{
		@extend .add-idea;
		border-bottom: 1px solid var(--white);
	}
}

// Stpries Module
div.mod-publivate-story {
	.stream-button {
		color: var(--white);
		text-decoration: none;
	}

	.mod-stories-author {
		color: var(--red);
	}
}

//Custom module chrome

.stream-title{
	text-align: center;
	padding: 10px 0 10px;
	color: var(--orange);
	font-weight: bold;
	font-size: 20px;
}

.stream-button{
	@extend .stream-title;
	border-top: 3px solid $pv-light-blue;
	margin-bottom: 0;
	background-color: $pv-light-blue;
}

// Challenges Module
div.mod-challenge{
	padding : 20px;
	@media screen and (max-width: 640px) {
		padding-top: 0;
		margin-top: 0 !important;
	}
}
.mod-challenge .countdown_section .countdown_amount{
	color: $dark !important;
}

// Idea module

.normal-idea{

	.card-header{

		h5.text-secondary{
			visibility: hidden;
		}
	}
}

#feedboard-stats-module {
	.profile-img {
		display: inline-block;
		height: 60px;
		width: 60px;
		border: 2px solid black;
		border-radius:30px;
	}

	h2 {
		font-size: 24px;
		margin-bottom: 0.5rem;
	}

	h3 {
		font-size: 14px;
		margin-bottom: 0.5rem;
	}

	p {
		font-size: 12px;
	}

	$blue: #4b96ae;
	$orange: #eb7d66;
	$dark: #4d4d4d;

	.progress-outer {
		width: 100%;
		height: 12px;
		border: 1px solid;
		border-radius: 6px;
		margin-bottom: 5px;

		&.blue { border-color: $blue; }
		&.orange { border-color: $orange; }
		&.dark { border-color: $dark; }

		.progress-inner {
			height: 10px;
			border-radius: 5px;

			&.blue { background-color: $blue; }
			&.orange { background-color: $orange; }
			&.dark { background-color: $dark; }
		}
	}
}

#feedboard-tags-module {
	a {
		font-size: 12px;
	}
}

#feedboard-stats-module {
	margin-top:3.5rem;
	border-radius:5px;
	position:relative;
	background-color: white;
	box-shadow: 2px 2px 10px rgba(203,198,195,.8);
	padding-bottom: 1px;
	.top-bar {
		width:100%;
		height:64px;
		background: transparent linear-gradient(96deg,#d46a43 0,#c0392b 100%) no-repeat padding-box;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		position:absolute;
	}
	.img-col {
		.img-pad {
			padding-left:1rem;
			display:inline;
		}
		img {
			height:80px;
			width:80px;
			z-index:1;
			position:relative;
		}
		.img-background {
			position:absolute;
			height:76px;
			width:76px;
			background-color:white;
			border-radius:38px;
			top:2px;
			right:17px;
		}
	}
	.main-body {
		padding-left:10px;
		padding-right:10px;
	}
	.you-color {
		font-size: 12px;
		color:#C0392B;
		&::before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background-color: #C0392B;
			margin-right: 5px;
			position: relative;
			bottom: 1px;
		}
	}
	.community-color {
		font-size: 12px;
		color:#5C97BF;
		&::before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background-color: #5C97BF;
			margin-left: 10px;
			margin-right: 5px;
			position: relative;
			bottom: 1px;
		}
	}
	ul.participation {
		list-style:none;
		li {
			opacity: .6;
			margin-bottom: 15px;
			display: flex;
			.icon-parent {
				flex:0 0 20px;
				& > span {
					padding-right:12px;
				}
			}
			.bar-parent {
				flex-grow:1;position:relative;
				.you-bar {
					height:8px;
					border-radius:6px;
					background-color:#C0392B;
					width:30%;
					margin-bottom: 4px;
				}
				.community-bar {
					height:8px;
					border-radius:6px;
					background-color:#5C97BF;
					width:45%;
				}
			}
		}
	}
}

.timeline-parent {
	p {
		margin-bottom: 12px;
	}
	& > div {
		padding-bottom:1px;
		position:relative;
	}
	.line {
		position:absolute;
		height:100%;
		border-left:1px solid grey;
		top:6px;
	}
	& > div:last-child .line {
		display: none;
	}
	.date-bullet, .event-bullet {
		margin-left: 15px;
		position:relative;
	}
	.date-bullet {
		font-size: .8rem;
		color: grey;
		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			left: -20px;
			top: 4px;
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background-color: grey;
		}
		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			left: -18px;
			top: 6px;
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background-color: #fafdff;
		}
	}
	.event-bullet {
		color: black;
		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			left: -20px;
			top: 5px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #fafdff;
		}
		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			left: -16px;
			top: 8px;
			width: 4px;
			height: 4px;
			border-radius: 2px;
			background-color: black;
		}
	}
}