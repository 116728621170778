.hero-section {
    width: 100%;
    padding: 70px 0;
}

#pv-challenge-wrapper {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0 !important;
}

.shortby-navigation.pv-collab{
    margin: 0;
    padding: 0 0 0 2em;
    height: 3em;
    line-height: 3em;
}

.sticky-menu-container{
    z-index: 0;
}

.hero-left {
    text-align: center;
    color: #00529c;

    h1 {
        color: #00529c;
        font-size: 48px !important;
        line-height: 1.2 !important;

        span {
            display: block;
            font-size: inherit;
            margin-top: 15px;
            font-weight: 700;
        }
    }
    h3 {
        color: #00529c;
    }
    .challenge-content{
        margin-top: 4.5rem;
        border: 1px solid #00529c;
        display: inline-block;
        padding: 1.2rem;
        background-color: $white;
        h2{
            color: #00529c;
            margin: 2.5rem 4.5rem 1rem;
            font-style: italic;
            font-size: 32px !important;
            font-weight: 700 !important;
        }
        img{
            margin-top: 1.2rem;
            margin-bottom: 1.2rem;
        }
    }
    p {
        color: #767676 !important;
    }

}

.hero-right {
    color: #fff;
    .box-right {
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 10px 30px;
        &:first-child {
            margin-bottom: 40px;
        }
    }
    .box-timer {
        text-align: center;
        span {
            margin-top: 0;
            color: #fff !important;
            font-size: 20px;
        }
        #timer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }
        .time {
            font-size: 30px !important;
        }

        #timer > * span {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
        }

    }
    .scoreboard {
        h4 {
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
        }
        .table {
            display: table;
            width: 100%;
            margin-bottom: 0;
            .tableBody {
                max-width: 150px;
            }
            .tableRow {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
            .tableCell {
                display: table-cell;

            }
        }

    }
}

.shortby-navigation {
    background-color: transparent;
    width: 100%;
    display: block;
    padding: 30px 0;
    z-index: 0;
}

.shortby-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.shortby-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    span {
        margin-right: 30px;
    }
    a,
    #tags-nav,
    #theme-nav {
        text-decoration: none;
        color: $dark-gray;
        margin-right: 30px;

        cursor: pointer;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        i {
            margin-left: 5px;

        }
        &:hover {
            color: $blue;
            text-decoration: none;
        }

    }
    .active {
        color: #4169a4;
    }
}

.sb-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .button {
        width: 120px;
        height: 40px;
        font-size: 13px;
        margin-right: 30px;
        line-height: 40px;
    }
    form {
        .input-group-append {
            button {
                background: none;
                border: none;
                padding: 3px;
                cursor: pointer;
            }
        }
    }
    .form-control {
        border: none;
        border-bottom: 2px solid $light-gray;
        border-radius: 0;
        width: 200px;
        input[type=search] {
            border-bottom: 2px solid #4dd0e1;
            -webkit-box-shadow: 0 1px 0 0 #4dd0e1;
            box-shadow: 0 1px 0 0 #4dd0e1;
            outline: 0 !important;
            &:focus {
                outline: 0 !important;
            }
        }
        box-shadow: none;
    }
}

.pv-collab {
    .big-button {
        display: block;
        width: 28%;
        height: 60px;
        margin: 3rem auto;
        font-size: 20px;
        &:active{
            background-color: #00529c;
            border-color: #00529c;
        }
    }

    .tags-item,
    .theme-item {
        margin-top: 20px;
        a {
            font-size: 13px;
            display: inline-block;
            margin: 5px 5px;
            background-color: $light-gray;
            padding: 5px 15px;
            color: #fff;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;

            &.active-tag,
            &.active-theme,
            &:hover {
                background-color: $blue;
                text-decoration: none;
                color: #fff;
            }
        }
    }

    #tags-button,
    #theme-button {
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;

        &.active-tag,
        &.active-theme,
        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }

    .tags-item,
    .theme-item {
        //display: none;
    }
}

.card-section .row:nth-of-type(2) {
    margin-top: 32px;
}

.pv-collab {

    .card-columns{

        @include media-breakpoint-up(lg){
            column-count: 2 !important;
        }
    }
    .card {
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px !important;
        -webkit-box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
        box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
        margin-bottom: 20px;
    }

    .card-image {
        position: relative;
        max-height: 200px;
        display: block;
        z-index: 10;
        overflow: hidden;

        &:hover {
            opacity: 0.9;
        }
    }

    .card-img {
        border-radius: 5px 5px 0px 0px !important;
    }

    .card-tags,
    .card-icons,
    .card-score {
        position: absolute;

    }

    .card-tags {
        bottom: 10px;
        left: 10px
    }

    .card-score {
        color: #fff;
        top: 10px;
        right: 10px;
        font-size: 12px;
        text-transform: uppercase;
        span {
            display: block;
            font-size: 24px;
            -webkit-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
            -moz-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
            text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
        }
    }    

    .card-icons {
        bottom: 10px;
        right: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: #fff;
        font-size: 13px;
        .icon {
            i {
                margin-right: 5px;
            }
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .card-tags,
    .card-score,
    .card-icons{

        //border-radius: 5px !important;
        //background-image: radial-gradient(black, rgba(255, 255, 255, 0)) !important;
        //background-size: cover;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.65) !important;
    }    

    .card-theme-tag {
        color: #fff;
        font-size: 13px;
        font-style: italic;

        &:hover {
            text-decoration: none;
            color: $blue;
        }
        &:first-child:before{
            content: "";
        }
        &:before{
            content: " | ";
        }

    }

    .card-block {
        padding: 20px;
        z-index: 999;
        height: 380px;

        .card-title:hover {
            color: #4169A4;
        }
    }

    .card-subtitle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 13px;
        margin-top: 10px !important;
        color: $light-gray;
        .card-author {
            a {
                //color: $blue;
                &:hover {
                    opacity: .8;
                    text-decoration: none;
                }
            }
        }
    }

    .card-date {
        margin-left: 10px;
        i {
            margin-right: 5px;
        }
    }

    .card-text {
        color: $light-gray;
        margin-bottom: 20px !important;
    }

    .card-button {
        display: block;
        width: 120px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;

        text-align: center;
        line-height: 40px;
        font-weight: 400;
        background-color: $blue;
        color: #fff;
        outline: none;
        border-radius: 3px;
        text-decoration: none;
        -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
        -webkit-transition: all .2s ease;
        -o-transition: all .2s ease;
        transition: all .2s ease;
        i {
            margin-left: 5px;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            color: #fff;
            opacity: .8;
            text-decoration: none;
        }
    }
}

#add-idea h3{
    font-size: 1.75rem;
    line-height: 1.5 !important;
}

#add-idea {
    span {
        color: white;
    }
}

.idea-like .special-idea card{
    @extend .h-md-100;
}


.idea-donate-cont{
    background: rgba(0,0,0,0.6);

    .donate-bg{
        background-image: url('/images/demo/donation_bg.svg');
        background-size: 32%;
        background-position: center;
        background-attachment: fixed;

        .don-text{
            margin: 2.5rem auto;

            .btn-light{
                background-color: #fff;
                border-color: #fff;
            }
        }
    }
}

.replies-btn.no-replies{
    vertical-align: initial !important;
}