.idea-rating-ajax{
  position: absolute;
  bottom: 100%;
  left: 0;
  /* White */
  background: #FFFFFF;
  /* Card Shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  form.rating-form{
    .rating{
      display: flex;
      flex-direction: row;
      padding: 16px;
      position: relative;
      :after {
        content: '.';
        display: block;
        height: 0;
        width: 0;
        clear: both;
        visibility: hidden;
      }
      .star{
        flex: none;
        order: 1;
        align-self: flex-start;
        margin: 0 4px;
        transition: all .2s ease-in-out;
        > a > span.pv-icon:not(on):before{
          content: "\e915";
          color: $black;
        }
        &.hover{
           > a > span.pv-icon:before{
             content: "\e914";
             color: #007FAA;
           }
        }
        &.on{
           > a > span.pv-icon:before{
             content: "\e914";
             color: $black;
           }
         }
      }
    }
  }
}

.sharing{
  position: absolute;
  bottom: 100%;
  right: 0;
  background: #FFFFFF;
  /* Card Shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  flex-direction: row;
  display: inline-block;
  padding: 16px;
  .first{
    flex: none;
    order: 0;
    align-self: center;
    display: flex;
    margin: 0; padding: 0;
    .social{
      flex: none; order: 1;
      align-self: flex-start;
      margin: 0 8px;
      > a > img{ border-radius: 5px; }
    }
  }
  .second{
    flex: none; order: 1;
    display: inline-block;
    align-self: center;
    padding: 0; margin: 16px 7px 0;
    p{
      font-style: normal; font-weight: normal; font-size: 12px; font-family: 'Lato';
      line-height: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

