.employee-hub{
  .section-container-title,
  .home-page-flow .flowctrl .flowHeader section,
  .button,
  .service-empty,
  .story-btn{
    background-color: $pv-brown ;
  }
  .h-services .container{
    h1{
      color: $pv-brown;
    }
    .pv-collab a{
      background-color: $pv-brown;
    }
    .collab-homepage-card {

      .service-score{
        background-color: $pv-brown;
      }
      .service-item{
        border: 2px solid $pv-brown;
      }

      .service-icon .icon span:hover{
        color: $pv-brown;
      }
      .service-plus{
        background-color: $pv-brown;
      }
    }

  }
  .service-empty{
    border: 2px solid $pv-brown;
  }
  .card .card-btn{
    border: 2px solid $pv-brown !important;
    color: $pv-brown !important;
  }
  .flowctrl form{
    border: 1px solid $pv-brown !important;
  }

  div.flow-card.first .navigation{
    margin-top: 0 !important;
    .card__next{
      float: none !important;
      text-align: center !important;
      display: block !important;
      width: fit-content !important;
      padding: 11px 7% !important;
      font-size: 21px !important;
      border-radius: 50px !important;
      background-color: $pv-brown !important;
      margin: 0 auto !important;

    }

  }

  //  Employee idea page
  .pv-collab {
    .card-button {
      background-color: $pv-brown;
    }
    .comment-report a,
    .comment-button .replies-btn,
    .comment-button a,
    .active,
    .shortby-section a:hover,
    .heading-bar a {
      color: $pv-brown!important;
    }

    .card-block {
      .card-title:hover {
        color: $pv-brown;
      }
      .card-subtitle {
        a {
          color: $pv-brown;
        }
      }

    }
    .primary-button {
      background-color: $pv-brown;
    }
  }

  //  stories
  .b-button {
    background-color: $pv-brown;
  }
  .card-button-group {
    .read-more-button {
      color: $pv-brown !important;
      border-color: $pv-brown;

      &:hover {
        background-color: $pv-brown;
        color: #fff!important;
      }
    }
    .story-abuse-link {
      color: $pv-brown!important;
    }
  }
  .pv-stories-wrapper {
    .banner-button a{
      color: $pv-brown!important;
    }
  }

//  PFAC
  .ofg-item {
    a {
      color: $pv-brown;
    }
  }

}

