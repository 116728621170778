/* Styling for all menu items can be added here  */

/* ========== MAIN NAV ========== */
.main-nav,
.menu{
	display: flex;
	list-style: none;
	li.active.current a, li.active.parent a{
		color: white;
		//text-shadow: 1px 1px black;
		padding: 1rem;
		//border-radius: 0.3rem;
		//background-color: rgba(0, 0, 0, 0.5);
		border-top: 2px solid #00529C;
		border-bottom: 2px solid #00529C;
		//background-color: #53CEFC;
	}
}
	
.main-nav{
	position: relative;
	z-index: 5;

	li{
		margin-right: 20px;
		a{
			font-size: 18px;
			color: white;
			line-height: 45px;
			font-weight: 600;
			//text-shadow: 1px 1px black;
			white-space: nowrap;
			@include transition;

			&:hover{
				text-decoration: none;
				border-bottom: 3px solid #ccc;
			}
		}
	}

	.login-button{
		color: $blue;
		margin-right: 30px;
		line-height: 45px;
		@include transition;

		&:hover{

			text-decoration: none;
		}
	}
	.register-button{
		display: inline-block;
		color: $blue;
		width: 150px;
		height: 45px;
		border-radius: 100px;
		border: 2px solid $blue;
		text-align: center;
		line-height: 41px;

		@include transition;
		&:hover{
			background-color: $blue;
			color: #fff !important;
			text-decoration: none;
		}
	}
}
/* ========== MOBILE HAMBURGER ICON ========== */
.hamburger{
	display: none;
	float: left;
	cursor: pointer;
	margin-right: 30px;
}
.hamburger .h-icon{
    display: block;
    width: 35px;
    height: 5px;
    background-color: $blue;
    margin: 6px 0;
}

/* ========== MOBILE NAV ========== */
.side-menu-overlay {
    /* overlay */
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .9);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    transition: opacity 1s;
}
.side-menu-wrapper{
	overflow: hidden;
	background: #4169A4;
	padding: 40px 0 0 40px;
	position: fixed;
	top: 0px;
	height: 100%;
	width: 250px;
	right: -290px;
	z-index: 1000;
	@include transition;
	ul{
		padding: 0;
		margin: 0;
		overflow-y: auto;
		width: 500px;
		height: 95%;
		li{
			padding: 10px 0;
			a{
				color: #fff !important;
				display: block;
				padding: 6px 4px 6px 4px;
				text-decoration: none;
				@include transition;
				&:hover{
					color:#000!important;
				}
			}
		}
	}
}
.side-menu-wrapper > a.menu-close {
    /* close button */
    padding: 8px 0 4px 23px;
    color: #fff;
    display: block;
    margin: -30px 0 -10px -20px;
    font-size: 35px;
    text-decoration: none;
    @include transition;
    &:hover{
		opacity: .8;
    }
}

/* Custom, iPhone Retina */
//@media only screen and (max-width : 320px) {
@include media-breakpoint-down(sm){
	.hamburger{

		margin-right: 20px;
	}
	.main-nav{
		.login-button{
			display: none;
		}
		.register-button{
			display: none;
		}

		.menu {
			margin-top: 10px;
			li {
				border-bottom: 1px solid rgba(0,0,0,.1);
				width: 100%;
				text-align: center;
			}
		}
	}


}

