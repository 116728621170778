@import "askus/askus";

#askus-container {

    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    >.row {
        margin-left: 0;
        margin-right: 0;
    }

    .askus-filter-row {
        .form-group {
            margin: 0 1.2rem 0 0;
        }
    }

}

.improvements.askus-comments .comment {
    margin-bottom: 20px;
}