#askus-question-save-btn {
  min-width: 25%;
  padding-top: 4px;
  padding-bottom: 4px;
}


.askus-challenge-content {
  ol,ul {
    margin-left: 1.5rem;
  }
}

#askus-detail {
  .item {
    border: none !important;
  }
}

form.add-ask {
  border-radius: 6px;
  background-color:white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 15px;
  .prompt {
    margin-bottom:15px;
    > * {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.askus-intro {
  height: 500px;
}

.askus-detail, .home-askus,
#answers {
  .card-columns {
    @include media-breakpoint-up(md) {
      column-count: 2;
    }

    @include  media-breakpoint-up(xs) {
      column-count: 1;
    }
  }

  .item-container {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .item {
    min-height: 190px;
    //border: 1px solid black;
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

    header {
      background-color: inherit;
      background-image: none;
      display: flex;
      flex-wrap: wrap;

      .askus-title {
        flex-basis: 85%;
        flex-grow: 1;
        font-size: 24px !important;
      }

      .score {
        margin-left: 40px;
        text-align: center;
        &-label {
          font-weight: 600;

        }
        &-value {
          text-align: center;
        }
      }
      .askus-category {
        flex-basis: 100%
      }
    }

    .askus-side {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
      width: 100%;

      & > div {
        margin: 0 1.5rem 0 0;
      }

      .wb-share {
        display: block;
        margin-left: auto;
      }
    }

  }

  .nav-tabs {
    .nav-link {
      font-size: 14px;
      padding: 1rem 0.25rem;
    }
  }
  .tab-content {
    padding: 1rem;
    .comment-name span.h5 {
      font-size: 24px !important;
    }
  }

  .paging {
    display: flex;

    .page {
      margin-right: 0.75rem;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.p-20px {
  padding: 20px !important;
}

.askus-description {
  padding: 15px 20px 5px 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  .askus-author {
  }
}


.askus-comments {
  .report-abuse {
    font: inherit;
  }

  textarea {
    min-height: 100px;
    width: 100%;
  }
}

// Askus Detail page
.askus-detail {
  .tab-content {
    padding: 1rem;
  }

  .askus-comments {
    padding: 0 !important;

    .comment {
      margin: 1rem 0;
      padding: 1rem;

      .comment-div {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
