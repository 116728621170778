/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 21-Feb-2018, 2:52:35 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

.collapse-title h2:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f077";
  float: right;
  color: grey;
}

.collapse-title.collapsed h2:after {
  content: "\f078";
}

#pv-content-top div.mod-challenge {
  padding-left: 7px;
  padding-right: 7px;
}

@include media-breakpoint-down(xs) {
  div.add-idea {
    padding-left: 0;
    padding-right: 0;
  }
  #add-idea {
    width: 100%;
    margin: 0;
  }
}

.pv-collab article.card {
  margin-bottom: 0;
}

#pv-challenge-wrapper {
  @include media-breakpoint-down(xs) {
    h1 {
      line-height: 1.2;
    }
  }
}

#ideas-page-body {
 .stats-and-tags {
   margin-bottom: 1.5rem;
 }
  .pv-pagination {
    margin-top: 2rem;
  }
}