.bottom-article{
	margin-left: -$grid-gutter-width/2;
	margin-right: -$grid-gutter-width/2;
	margin-top: 60px;
	background-color: #F3F3F3;

	h1{
		margin-top: 80px;
		margin-bottom: 20px;
	}
	img{
		height: 240px;
		//position: relative;
		top: -100px;
	}
	p:last-child{
		margin-bottom: 80px;
	}

	.container {
		background-color : inherit;
	}
}

/* Small Devices, Tablets */
//@media only screen and (max-width : 768px){
@include media-breakpoint-down(sm){
	.bottom-article{
		margin-top: 20px;
		h1{
			margin-top: 40px;
		}
		p:last-child{
			margin-top: 40px;
		}

	}
}

@include media-breakpoint-down(xs) {
	.bottom-article {
		img { display: none; }
	}
}