$dark-gray: #414141;
$light-gray: #767676;

$blue: #4169a4;

.pv-collab {
  .button {
    display: block;
    width: 180px;
    height: 45px;
    cursor: pointer;
    border: none;
    text-align: center;
    line-height: 45px;
    font-weight: 600;
    background-color: $blue;
    color: #fff;
    outline: none;
    border-radius: 3px;
    text-decoration: none;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #fff;
      opacity: .8;
      text-decoration: none;
    }
  }


  h2 {
    font-size: 36px !important;
    color: $dark-gray;
    //font-weight: 600 !important;
    line-height: 36px !important;
  }
  .h3,
  h3 {
    font-size: 24px !important;
    color: $dark-gray;
    font-weight: 600 !important;
    line-height: 24px;
  }
  p {
    /*font-size: 16px !important;*/
    /*font-weight: 400 !important;*/
    line-height: 26px;
    letter-spacing: .1px;
    /*margin: 0 !important;*/
  }
  /* ========== HEADER TOP ========== */

  .header-top {
    background-image: url('/images/hospital/header-top.png');
    height: 200px;
    background-position: center;
    background-size: cover;
  }

  .active {
    color: $blue ;
  }

  /* ========== STICKY NAV ========== */
  .sticky {
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 20px 0px !important;
  }

  .idea-detail{
    h3{
      font-size: 1.75rem !important;
    }
  }
}