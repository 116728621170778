.idea-iframe {
  .mfp-iframe-holder .mfp-content{
   width: 50%;
    iframe{
      border-radius: 5px;
    }
  }
}

.component.window body{
  padding: 0 !important;
  form.add-idea{
    .pv-collab .card{
      margin: 0;
    }

    .idea-action-btn{
      background-color: #eb7d66;
      color: $white !important;
    }
  }
}