
.record-view {
  .chime-welcome {
    display: none;
  }

  .chime-panel {
    .textual-panel,
    .controls {
      display: none !important;
    }
  }
}

.pv-modal-window,
#more-settings-modal {
  display: none;
  position: fixed;
  z-index: 5000;
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &.show {
    display: block;
  }

  .actual-popup {
    background-color: white;
    border-radius: 5px;
    width: Min(600px, 100vw);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 15px 0 15px;

    label {
      display: block;
      margin-top: 10px;
      margin-bottom: 4px;
    }

    select {
      width: 100%;
    }

    #preview_local {
      .preview_local_view{
        height: 260px !important;
        width: 100%;
      }
    }

    .footer {
      border-top: 1px solid #C6C6C6;
      padding: 5px 0;
      margin-top: 15px;

      button.btn-primary,
      button {
        color: #000;
        display: block;
        border: none;
        background-color: white;
        padding: 10px 0;
        width: 100%;
        border-radius: 5px;

        &:hover {
          background-color: grey;
          color: #ddd;
        }
      }
    }
  }
}

#chime-app {
  z-index: 5000;
}

.chime-welcome {
  font-family: Montserrat, sans-serif;

  h1 {
    font-weight: bold;
    font-size: 32px !important;
    line-height: 44px;
  }

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
  }
}

.chime-panel {
  font-family: Lato, sans-serif;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 15px;

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #37395B;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    padding: 0 9px 0 7px;
    position: relative;
    z-index: 1;
    color: white;

    .left {
      button {
        display: inline-block;
        padding: 0 20px;
        background-color: transparent;
        color: white;
        border: none;
        height: 50px;

        &:hover {
          background-color: white;
          color: black;
        }

        &:disabled * {
          color: #c6c6c6;
        }
      }

      #self-mute-audio .icon {
        margin-right: 10px;
      }

      #self-mute-video .icon {
        margin-right: 10px;
      }

      #header-more .icon {
        margin-right: 4px;
      }

      .button-container {
        display: inline-block;
        position: relative;
      }

      @media screen and (max-width: 640px) {
        #self-mute-video {
          display: none;
        }
        .self-mute-video-more {
          display: block;
        }
      }
      @media screen and (min-width: 640px) {
        #self-mute-video-more {
          display: none;
        }
        .self-mute-video-more {
          display: none;
        }
      }

      //#header-more:hover  ~ .dropdown {
      //    display: block;
      //}

      #header-more ~ .dropdown {
        background-color: #37395b;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        display: none;
        left: 0;
        min-width: 200px;
        padding-top: 5px;
        position: absolute;
        top: 100%;

        ul {
          list-style: none;
          margin-bottom: 0;
          width: 100%;

          li {
            margin-bottom: 5px;
            width: 100%;

            .option {
              color: white;
              width: 100%;
              padding: 2px 10px;
              display: block;

              &:hover {
                color: black;
                background-color: white;
              }

              .fas {
                margin-left: 5px;
              }

              .fa-stop-circle {
                color: red;
              }
            }
          }
        }
      }
    }

    .right {
      #end-meeting {
        border-radius: 5px;
        border: 1px solid #CF000F;
        color: #CF000F;
        background-color: white;
        padding: 7px 11px;
        margin-right: 8px;

        &:hover {
          color: white;
          background-color: #CF000F;
        }
      }

      #header-leave {
        border-radius: 5px;
        border: none;
        color: white;
        background-color: #CF000F;
        padding: 8px 12px;

        &:hover {
          color: #CF000F;
          background-color: white;
        }
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 0;

    .visual-panel {
      flex: 1 0 0;
      position: relative;

      .chat-room {
        height: calc(100vh - 170px);
        min-height: calc(100vh - 178px) !important;
        max-height: 500px;
      }

      .description {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 6px 15px;
        display: flex;

        span{
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          width: calc(100% - 25px) !important;
        }
      }

      .video-large {
        flex-grow: 1;
        position: relative;
        width: 100%;
        background-color: black;
        @media screen and (max-width: 990px) {
          padding-top: 60%; // aspect ratio
        }

        .feed {
          @media screen and (min-width: 990px) {
            height: 100%;
            width: 100%;
          }
          @media screen and (max-width: 990px) {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
          }
          border: 1px solid #37395B;
        }
      }

      $thumb-height: 90px;

      #chime-carousel {
        position: relative;
        overflow: hidden;
        height: $thumb-height;
        border: 1px solid #37395b;
        background: black;
        @media screen and (min-width: 990px) {
          border-bottom-left-radius: 5px;
        }

        .previous, .next {
          position: absolute;
          top: 50%;
          background-color: transparent;
          border: none;
          font-size: 20px;
          -webkit-text-stroke: 2px white;
          z-index: 1;
        }

        .previous {
          left: 4px;
          transform: translateY(-58%) rotate(90deg);
        }

        .next {
          right: 4px;
          transform: translateY(-58%) rotate(-90deg);
        }

        ul.thumbnail-list {
          list-style: none;
          margin-bottom: 0;
          height: $thumb-height;
          position: absolute;
          top: 0;
          left: 0;
          width: max-content;

          > li {
            position: relative;
            display: inline-block;
            height: $thumb-height;
            background-color: black;
            width: 160px;
          }
        }
      }
    }

    .textual-panel {
      width: 224px;
      flex: 0 0 224px;
      min-height: 0;
      @media screen and (max-width: 990px) {
        flex: 1 0 100%;
      }
      @media screen and (min-width: 990px) {
        display: flex;
        flex-direction: column;
        &.absolute {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .title-bar {
        flex: 0 0 auto;
        background-color: #37395B;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        padding: 4px 10px;
        color: white;
        cursor: pointer;

        .arrow {
          font-size: 12px;
          display: inline-block;
          margin-left: 8px;
          transform: rotate(90deg);
          transition: transform .4s ease-out;
        }

        &.collapsed .arrow {
          transform: rotate(0deg);
        }

        span.pendingCount {
          float: right;
          color: #fff !important;
          font-weight: bold;

          h2 {
            font-size: 16px !important;
            color: #fff !important;
          }
        }

      }

      .chime-chat-bar {
        .badge {
        }

        .draw-attention {
          animation: draw-attention 1.5s linear infinite;
        }

        @keyframes draw-attention {
          50% {
            opacity: 0.5;
          }

        }
      }

      ul.names {
        flex: 1 1 0;
        transition: flex .2s ease-out;
        min-height: 0;
        background-color: white;
        overflow-y: auto;
        margin-bottom: 0;

        &.collapsing {
          flex: 0;
        }

        &.collapse:not(.show) {
          display: none;
        }

        @media screen and (max-width: 990px) {
          max-height: 350px;
        }
        @media screen and (min-width: 990px) {
          display: flex;
          flex-direction: column;
        }

        li#pending-admission ~ li {
          .mute-button,
          .mute-video,
          .hand-raised {
            display: none;
          }
        }

        li#moderators ~ li,
        li#attendees ~ li {
          .mute-button,
          .mute-video,
          .hand-raised {
            display: initial;
          }
        }

        > li {
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.roster-header span {
            border-bottom: 1px solid #000;
            display: block;
            font-weight: bold;
            padding-bottom: 5px;
            width: 100%
          }

          .participant-dropdown {
            position: absolute;
            top: 100%;
            background-color: white;
            border: 1px solid black;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            z-index: 5001;

            > ul {
              list-style: none;

              > li {
                padding: 2px 5px;
              }
            }
          }

          .icons {
            display: flex;
            font-size: 12px;

            button {
              background-color: transparent;
              border: none;

              &.disabled {
                .fas {
                  color: #c6c6c6 !important;
                }
              }
            }

            .fas {
              line-height: 15px;
              padding: 0 2.0px;
            }

            .private-message-icon {
              color: #007FAA;
            }

            .hand-raised {
              color: #007FAA;
            }

            .audio-enabled {
              color: #1D781D;
            }

            .video-enabled {
              color: #1D781D;
            }

            .audio-disabled, .video-disabled {
              color: #CF000F;
            }

            .accept-button .fa-check {
              color: #1D781D;
            }

            .kick-button .fa-times {
              color: #CF000F;
            }
          }
        }
      }

      #collapsing-chat {
        flex: 1 1 0;
        min-height: 0;
        background-color: white;
        transition: flex .2s ease-out;
        @media screen and (min-width: 990px) {
          display: flex;
          flex-direction: column;
        }

        &.collapsing {
          flex: 0;
        }

        &.collapse:not(.show) {
          display: none;
        }

        .expanding-container {
          flex: 1 1 0;
          min-height: 0;
          overflow-y: auto;
          @media screen and (max-width: 990px) {
            min-height: 24px;
            max-height: 350px;
          }

          ul.messages {
            margin-bottom: 0;
            list-style: none;

            > li {
              padding: 5px 10px;

              .sender {
                font-weight: bold;
              }
            }
          }
        }

        #message-form {
          flex: 0 0 auto;
          width: 100%;
          position: relative;

          input[type="text"] {
            width: 100%;
            padding: 12px 44px 11px 10px;
            border-bottom-right-radius: 5px;
            border: 1px solid black;
            @media screen and (min-width: 990px) {
              border-left: none;
            }
          }

          #send-message {
            border: none;
            background: transparent;
            position: absolute;
            padding: 0;
            right: 12px;
            top: 14px;
            cursor: pointer;
            &:active,
            &:focus {
              border: none;
            }
          }

        }
      }
    }
  }

  #pin-main {
    background: #000;
    background-repeat: no-repeat;
    background-size: 700px 500px;
    background-position: center;
    background-image: url(/images/chime/pv-icon-user-solid-white.svg);
  }

  .chat-room .row, .chat-room .container {
    height: 100%;
  }

  .chat-room .col-12 {
    padding: 0px 15px;
    height: 100%;
  }

  .call-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    margin: 0px;

    .waiting {
      position: absolute;
      z-index: 999;
      background: rgba(0,0,0, 0.5);
      right: 225px;
      text-align: center;
      padding: 4px 10px;
      color: #AAF2A2;
      top: 1px;
    }
  }

  .admitting {
    .waiting {
      right:0px !important;
    }
  }
  .calls {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .call {
    width: 100%;
    height: 100%;
    background-color: #000;
    border: #999 solid 1px;
    position: relative;
    background-size: cover;
    background-position: center top;
  }

  .call .caller {
    position: absolute;
    bottom: 0;
    left: 0px;;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    padding: 3px;
    text-align: left;
    background: rgba(0, 0, 0, 0.5);
  }

  .top-left {
    position: absolute;
    top: 2px;
    left: 3px;
    cursor: pointer !important;
    z-index: 9999;
    background: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }

  .pin-button {
    background: #fff;
    color: #000;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    border-radius: 30px;
  }

  .unpinImage {
    height: 15px !important;
  }

  .col-md-12 {
    height: 100%;
  }

  .col-md-6, .col-md-4 {
    height: 50%;
  }

  .chat-room video {
    top: 50%;
    width: 100%;
    max-height: 100%;
    transform: translateY(-50%) !important;
  }

  .single .col-md-6, .single .col-md-4 {
    height: 100%;
  }

  @media (min-width: 992px) {
    .call-container {
    }
  }
  @media (min-width: 768px) {
    .column {
      padding: 0px !important;
    }
  }
}