@media only screen and (max-width: 100vh) {
  .wrapper {
    width: auto !important;
    //height: auto !important;
  }

  h1 {
    //font-size: 6.25vw !important;
    //letter-spacing: 0.8125vw !important;
    //line-height: 7.5855vw !important;
  }

  .hh-improve {
    transform: scale(0.9,0.9);
  }

  .hh-improve .hosphuboutputs.shrunk {
    transform: translateY(0) scale(0, 0) !important;
  }

  .hh-improve .hospitaloutputs.shrunk {
    transform: translateY(0) scale(0, 0) !important;
  }

  .hh-booking > .hh-demobutton {
    display: none;
  }

  .hh-listen .hosphubbody.expanded {
    animation: none !important;
  }

  .hh-booking > .hh-previews > img {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 860px) {
  .hh-share .share-iso {
    transform: scale(0.8, 0.8);
    transform-origin: top;
  }

  /*h2{
    font-size: 18px !important;
  }*/
}

@media only screen and (max-width: 710px) {
  .hh-share .share-iso {
    transform: scale(0.65, 0.65);
    transform-origin: top;
  }

  .hh-share > h2 {
    //transform: translateY(-14vh);
  }
}

@media only screen and (max-width: 570px) {
  .hh-share .share-iso {
    transform: scale(0.5, 0.5);
    transform-origin: top;
  }

  .hh-demobutton-lg {
    font-size: 24px !important;
  }

  .hh-share > h2 {
    //transform: translateY(-22vh);
  }
  .home-page .hh-booking>.hh-previews>img{
    max-height: 13vh;
  }
}

@media only screen and (max-width: 440px) {
  .hh-share .share-iso {
    transform: scale(0.4, 0.4);
    transform-origin: top;
  }

  .hh-share > h2 {
    //transform: translateY(-30vh);
  }
}
