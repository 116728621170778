/*

 Fade element bottom, add an element to the bottom of the parent element ie: <div>sdfsdfs<div class="fade-bottom"></div></div>

 */
.fade-bottom{
    background-image: linear-gradient(
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.7) 100%
    );

    bottom: 0;
    left: 0;
    height: 1rem;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    position: absolute;
    width: 100%;
}

.hover-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.2s !important;
    transition-property: all !important;
    transition-timing-function: ease-out;
}
.hover-float {
    &:hover, &:focus, &:active {
        transform: translateY(-2px);
    }
}
.hover-primary {
    &:hover, &:focus, &:active {
        color: $primary !important;
    }
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-100 {
    bottom: 100%;
}

.opacity0 {
    opacity: 0 !important;
}

.opacity20 {
    opacity: 0.2;
}

.opacity1 {
    opacity: 1 !important;
}

.no-box-shadow {
    box-shadow: none !important;
}

.text-black {
    color: black;
}

.icon-16 {
    height: 1rem;
    width: 1rem;
}

.icon-18{
    height: 1.2rem;
}

.h-25px{
    height: 1.6rem;
}

.h-32px {
    height: 32px;
}

.h-48px {
    height: 48px;
}

.h-64px {
    height: 64px;
}

.h-128px {
    height: 128px;
}

.h-256px {
    height: 256px;
}

.h-500px {
    height: 500px;
}

.h-700px {
    height: 700px;
}

@media (min-width: 767px) {
    .h-md-100{
        height: 100%;
    }
}

.w-128px {
    width: 128px;
}

.pt-8 {
    padding-top: 8rem !important;
}

.mb-down{
    margin-bottom: 2rem !important;
}

.max-vh-100 {
    max-height: 100vh;
}

@include media-breakpoint-up(lg) {
    .vh-lg-100 {
        height: 100vh !important;
    }
}

@include media-breakpoint-down(xs) {
    .no-px-mobile {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.z-inherit {
    z-index:inherit !important;
}