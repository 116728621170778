.home-stories{
	a.story-title{
		text-align: left;
		font: Bold 20px/24px Lato;
		letter-spacing: 0;
		color: #000000;
		opacity: 1;
	}
	.story-stats{
		text-align: left;
		font: normal 12px/15px Lato;
		letter-spacing: 0;
		opacity: 1;
		margin-right: auto;
		margin-left: auto;
		>div.col-auto {
			margin-top: 1rem !important;
		}
		.dot{
			background: #4D4D4D 0 0 no-repeat padding-box;
			width: 4px;
			height: 4px;
			opacity: 1;
			position: absolute;
			right: 0;
			top: 7px;
		}
	}
}
